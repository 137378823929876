import axios from 'axios';

const endpoint = "https://app.nawaten.online/2020/";
export class ApiHelper {
  public static async callApi(
    path: string,
    method: string,
    params: {},
    callSuccess: (response: any) => void,
    callError: (error: any) => void = error => {
      return;
    },
    tokenError: (error: any) => void = error => {
      return;
    },
  ) {
    switch (method) {
      case 'post':
        await axios.post(endpoint + path, params, {})
          .then(response => {
            callSuccess(response);
          })
          .catch(error => {
            callError(error);
          });
        break;
      case 'put':
        await axios.put(endpoint + path, params, {})
          .then(response => {
            callSuccess(response);
          })
          .catch(error => {
            callError(error);
          });
        break;
      case 'delete':
        await axios.delete(endpoint + path, {})
          .then(response => {
            callSuccess(response);
          })
          .catch(error => {
            callError(error);
          });
        break;
      default:
        await axios.get(endpoint + path, {})
          .then(response => {
            callSuccess(response);
          })
          .catch(error => {
            callError(error);
          });
    }
  }
}
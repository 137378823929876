









import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProjectModule, Project } from '@/store/projects';
import { ApiHelper } from '@/ApiHelper';
import { AppModule } from '@/store/app';
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
    Loading
  },
  head: {
    title() {
      return {
        inner: "なわてん 2020年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展"
      }
    }
  },
})
export default class AppComponent extends Vue {
  private get loading() {
    return !AppModule.loaded;
  }
}





















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Scene from '@/components/Scene.vue'
import { BallInformation } from '@/store/app'
import { ApiHelper } from '@/ApiHelper';
import { AppModule } from '@/store/app';
import { ProjectModule, Project } from '@/store/projects';
import Projects from '@/components/Projects.vue'
import Departments from '@/components/Departments.vue'
import { DepartmentModule, Department } from '@/store/departments';

@Component({
  components: {
    Scene,
    Projects,
    Departments
  },
  head: {
    title: {
      inner: "なわてん 2020年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展"
    },
    meta() {
      return [
        { property: 'og:title', content: document.title, id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: 'なわてん - 2020年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2021年2月13日（土）、14日（日）11時〜17時【オンライン開催】', id: 'ogdescription'},
        { property: 'og:url', content: document.URL, id: 'ogurl' },
        { property: 'og:image', content: document.URL + 'img/ogp.png', id:'ogimage' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
        { property: 'twitter:site', content: '@nawaten_info', id: 'twittersite' },
      ]
    },
  },
})
export default class Home extends Vue {
  private informations: BallInformation[] = [];
  private departments: Department[] = [];
  private keyword = '';
  private placeholder = '';
  private prevPlaceholder = '';
  private nextPlaceholder = '';
  private phIsMoving = false;
  private cnt = 0;

  private created(){
    AppModule.setLoaded(true);
  }
  private mounted(){
    const count = window.innerWidth>1100 ? 35 : 20
    ApiHelper.callApi(`wp-json/wp/v2/project_theme?_embed&per_page=100`, 'get', {}, res => {
      const tmp = res.data.sort((a: any, b: any)=>{
        return b.count-a.count
      }).filter((v: any) => { return v.count>0 }).slice(0, count);
      for (const term of tmp) {
        this.informations.push({
          title: term.name as string,
          path: `/themes/${term.id}/`,
          imageUrl: term.acf['theme-icon'] ? term.acf['theme-icon'].sizes.thumbnail : undefined,
          count: term.count as number
        });
      }
      setInterval( this.rotateKeyword, 3000);
    });

    ApiHelper.callApi(`wp-json/wp/v2/department?_embed`, 'get', {}, res => {
      DepartmentModule.setDepartment(res.data.map((v: any)=>{
        return {
          id: v.id,
          description: v.description,
          name: v.name,
          eyecatch: v.acf.eyecatch.url,
          movie: v.acf.movie ? v.acf.movie.url : ""
        } as Department;
      }));
    });
    this.getProjects();
    this.rotateKeyword();
  }

  @Watch('keyword')
  private search(){
    this.getProjects();
  }

  private getProjects(){
    ApiHelper.callApi(`wp-json/wp/v2/project?per_page=12&_embed&search=${this.keyword}`, 'get', {}, res => {
      ProjectModule.setProjects(res.data.map((v: any)=>{
        return {
          id: v.id,
          title: v.title.rendered as string,
          eyecatch: v.featured_image_url as string,
          terms: v._embedded['wp:term'] ? v._embedded['wp:term'].flat() : [],
          squareEyecatch: v.acf.squareEyecatch as string,
        } as Project;
      }));
    });
  }
  //private get informations(){
  //  return BallInformationModule.informations;
  //}

  private rotateKeyword() {
    if (this.cnt >= this.informations.length){
      this.cnt = 0;
    }
    if (this.informations[this.cnt]){
      this.prevPlaceholder = this.placeholder;
      this.phIsMoving = true;
      setTimeout(()=>{
        this.nextPlaceholder = this.informations[this.cnt].title;
      },10)
    }
    this.cnt++;
  }
  private animated() {
    this.placeholder = this.nextPlaceholder;
    this.phIsMoving = false;
  }
}






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectModule, Project } from '@/store/projects';
import ProjectComponent from '@/components/ProjectComponent.vue';

@Component({
  components: {
    ProjectComponent
  }
})
export default class Projects extends Vue {
  private get projects(){
    return ProjectModule.projects;
  }
}


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Project } from '@/store/projects'
@Component
export default class ProjectComponent extends Vue {
  @Prop() project!: Project;
}
